import { likeRegExp } from '@/offline/database'

export default {
  _selectSolicitudMaterial (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.solicitud_material)
  },
  async selectSolicitudMaterial (Vue, filter, search, sorter, page, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    let where = []
    let whereSearch = []
    let whereFilter = []
    if (search) {
      const reQ = likeRegExp(search)
      whereSearch.push(
        Vue.$offline.db.op.or(
          tables.solicitud_material.descripcion.match(reQ),
        )
      )
    }
    where.push(
      Vue.$offline.db.op.and(
        tables.solicitud_material.idparte_trabajo.eq(idparteTrabajo),
        tables.solicitud_material.estado.gt(0),
        ...whereSearch,
        ...whereFilter
      )
    )
    return [
      await this._selectSolicitudMaterial(Vue)
        .where(...where)
        .limit(Vue.$offline.db.ITEMS_PER_PAGE)
        .skip(page * Vue.$offline.db.ITEMS_PER_PAGE)
        .exec(),
      []
    ]
  },
  async selectSolicitudMaterialRows (Vue, pks) {
    const tables = Vue.$offline.db.tables
    return await this._selectSolicitudMaterial(Vue)
      .where(tables.solicitud_material.idsolicitud_material.in(pks))
      .exec()
  },
}
