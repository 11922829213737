<template>
  <b10-legend
    :showing.sync="internalShowing"
  >
    <template
      slot="legend"
    >
      <v-simple-table>
        <template
          #default
        >
          <tbody
            v-for="item in SOLICITUD_MATERIAL.estados"
            :key="item.estados"
          >
            <tr>
              <td>
                <v-chip
                  :color="SOLICITUD_MATERIAL.coloresEstado[item]"
                  dark
                >
                  <span
                    class="font-weight-bold subtitle-1"
                  >{{ $options.filters.initials(SOLICITUD_MATERIAL.descripcionEstados[item]) }}</span>
                </v-chip>
              </td>
              <td>
                {{ SOLICITUD_MATERIAL.descripcionEstados[item] }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </b10-legend>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'
import { SOLICITUD_MATERIAL } from '@/utils/consts'

export default {
  mixins: [internalShowingMixin],
  data () {
    return {
      SOLICITUD_MATERIAL
    }
  },
}
</script>
